import React from 'react';
import titleImageIcon from 'images/features/administration_header.svg';

import AnchorLinks from '../links';
import SectionWrapper from '../Core/SectionWrapper';
import WebPlatformSection from './webplatform';
// import GreetingSection from './greeting'; @todo will add later

const Administation: React.FC = () => (
  <SectionWrapper
    title="components.features.administation.title"
    titleImage={titleImageIcon}
    hashTag={AnchorLinks.adminFeatures}
  >
    <WebPlatformSection />
  </SectionWrapper>
);

export default Administation;
