/** in sections
  without hash! always since using react-anchor-link-smooth
  but in anchor links with # sign */

const AnchorLinks = {
  adminFeatures: '#adminFeatures',
  webPlatform: '#webPlatform',
  greeting: '#greeting',
  userFeatures: '#userFeatures',
  app: '#app',
};

export default AnchorLinks;
