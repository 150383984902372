import React from 'react';
import { Container } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import { getLocale, Locales } from 'helpers/utils/locale';

import AnchorMenu from 'components/AnchorMenu';

import AnchorURLs from './links';
import Administation from './Administration';
import UserFeatures from './UserFeatures/index';
import styles from './index.module.scss';

const translationMenu = 'components.features.anchorMenu';
const AnchorLinks = [
  {
    header: {
      label: `${translationMenu}.administation.header`,
      hashTag: AnchorURLs.adminFeatures,
    },
    subMenu: [
      {
        label: `${translationMenu}.administation.sub1`,
        hashTag: AnchorURLs.webPlatform,
      },
    ],
  },
  {
    header: {
      label: `${translationMenu}.userFeatures.header`,
      hashTag: AnchorURLs.userFeatures,
    },
    subMenu: [
      {
        label: `${translationMenu}.userFeatures.sub1`,
        hashTag: AnchorURLs.app,
      },
    ],
  },
];

const Features: React.FC = () => {
  const { locale } = useIntl();
  const pad = getLocale(locale) === Locales.En ? styles.langEn : styles.langFr;
  const headerPos = { top: '15%', left: '10px' };
  return (
    <Container>
      <AnchorMenu
        links={AnchorLinks}
        headerPos={headerPos}
        anchorLinkOffsetTop={100}
      />
      <div className={`${styles.container} ${pad}`}>
        <Administation />
        <UserFeatures />
      </div>
    </Container>
  );
};

export default Features;
