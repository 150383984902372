import React from 'react';
import { SectionConfigType } from 'types/features';

import titleImage from 'images/features/sections/app.png';
import localPhoneIcon from 'images/features/userfeatures/local-phone.svg';
import textSmsIcon from 'images/features/userfeatures/textsms.svg';
import syncIncon from 'images/features/userfeatures/sync.svg';
import thumbUpIcon from 'images/features/userfeatures/thumb-up-alt.svg';
import buildIcon from 'images/features/userfeatures/build.svg';
import avTimerIcon from 'images/features/userfeatures/av-timer.svg';
import mobileOffIcon from 'images/features/userfeatures/mobile-off.svg';
import voiceMailIcon from 'images/features/userfeatures/voicemail.svg';
import graphicEqIcon from 'images/features/userfeatures/graphic-eq.svg';
import mmsIcon from 'images/features/userfeatures/mms.svg';

import AnchorLinks from '../links';
import Section from '../Core/Section';
import Block from '../Core/Block';

const appTranslationRoot = 'components.features.userfeatures.app';

const WebPlatformConfig: Array<SectionConfigType> = [
  {
    title: `${appTranslationRoot}.feature1.title`,
    description: `${appTranslationRoot}.feature1.description`,
    icon: localPhoneIcon,
  },
  {
    title: `${appTranslationRoot}.feature2.title`,
    description: `${appTranslationRoot}.feature2.description`,
    icon: textSmsIcon,
  },
  {
    title: `${appTranslationRoot}.feature3.title`,
    description: `${appTranslationRoot}.feature3.description`,
    icon: syncIncon,
  },
  {
    title: `${appTranslationRoot}.feature4.title`,
    description: `${appTranslationRoot}.feature4.description`,
    icon: thumbUpIcon,
  },
  {
    title: `${appTranslationRoot}.feature5.title`,
    description: `${appTranslationRoot}.feature5.description`,
    icon: buildIcon,
  },
  {
    title: `${appTranslationRoot}.feature6.title`,
    description: `${appTranslationRoot}.feature6.description`,
    icon: avTimerIcon,
  },
  {
    title: `${appTranslationRoot}.feature7.title`,
    description: `${appTranslationRoot}.feature7.description`,
    icon: mobileOffIcon,
  },
  {
    title: `${appTranslationRoot}.feature8.title`,
    description: `${appTranslationRoot}.feature8.description`,
    icon: voiceMailIcon,
  },
  {
    title: `${appTranslationRoot}.feature9.title`,
    description: `${appTranslationRoot}.feature9.description`,
    icon: graphicEqIcon,
  },
  {
    title: `${appTranslationRoot}.feature10.title`,
    description: `${appTranslationRoot}.feature10.description`,
    notice: `${appTranslationRoot}.feature10.notice`,
    icon: mmsIcon,
  },
];

const AppSection: React.FC = () => {
  const webPlatformBlockArray = WebPlatformConfig.map((item) => (
    <Block
      key={item.title}
      title={item.title}
      description={item.description}
      notice={item.notice || ''}
    >
      <img src={item.icon} alt="featureImage" />
    </Block>
  ));
  return (
    <Section
      title="components.features.userfeatures.app.title"
      titleImage={titleImage}
      hashTag={AnchorLinks.app}
      showBreakLine={false}
    >
      {webPlatformBlockArray}
    </Section>
  );
};

export default AppSection;
