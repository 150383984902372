import React, { useEffect, useState } from 'react';
import { flatten } from 'flat';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  stringContainsWords,
  FlattenJson,
} from 'helpers/utils/url';
import Menu from 'helpers/constants/menuConstants.json';
import { Container, Row, Col } from 'react-bootstrap';
import Link from 'components/UI/Link';

import styles from './BannerInnerLink.module.scss';

export enum Pages {
  Pricing = 'pricing',
  Features = 'features',
}

interface BannerInnerLinkProps {
  page: string;
  urlToPage?: string;
}

const BannerInnerLink: React.FC<BannerInnerLinkProps> = ({
  page = Pages.Pricing,
}) => {
  const [isCurrentPagePricing, setIsCurrentPagePricing] = useState(false);
  useEffect(() => {
    setIsCurrentPagePricing(stringContainsWords(window.location.href, 'pricing', 'tarifs'));
  }, []);
  const { locale } = useIntl();
  const flattenMenu: FlattenJson = flatten(Menu);
  const featuresLink = flattenMenu[`HEADER.FEATURES.URL.${locale}`];
  const pricingLink = flattenMenu[`HEADER.PRICING.URL.${locale}`];

  let translation = '';
  switch (page) {
    case Pages.Pricing:
    default:
      translation = 'components.banners.bannerInnerLink.pricing';
      break;
    case Pages.Features:
      translation = 'components.banners.bannerInnerLink.features';
      break;
  }
  const title = (
    <Link
      to={isCurrentPagePricing ? featuresLink : pricingLink}
      className={styles.link}
    >
      <FormattedMessage
        id={translation}
        values={{
          a: (...chunks: string[]): JSX.Element => (
            <span
              className={styles.targetWord}
            >
              {chunks}
            </span>
          ),
        }}
      />
    </Link>
  );
  return (
    <Container className={styles.main}>
      <Row>
        <Col className={styles.mainText}>
          {title}
        </Col>
      </Row>
    </Container>
  );
};

export default BannerInnerLink;
