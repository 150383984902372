/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import mSignal, { SIGNALS } from 'helpers/constants/signals';
import { removeHashSignFromLink } from 'helpers/utils/url';
import { isDivTop } from 'helpers/utils/html';

import styles from './Section.module.scss';

interface FeatureSectionProps {
  title: string;
  titleImage: string;
  titleImageUpOffset?: boolean;
  showBreakLine?: boolean;
  hashTag: string;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  children,
  title,
  titleImage,
  titleImageUpOffset = false,
  showBreakLine = false,
  hashTag,
}) => {
  const trackScrolling = (): void => {
    const wrappedElement = document.getElementById(removeHashSignFromLink(hashTag));
    if (isDivTop(wrappedElement)) {
      mSignal.dispatch(SIGNALS.ACTIVE_HUMAN_SCROLL, hashTag);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return (): void => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, []);

  const breakLine = showBreakLine ? (
    <hr className={styles.breakLine} />
  ) : null;

  const titleImgOffset = titleImageUpOffset ? styles.titleImageUpper : '';

  return (
    <>
      <div id={removeHashSignFromLink(hashTag)} className={styles.headerTag} />
      <Container className={styles.container}>
        {breakLine}
        <Row id={removeHashSignFromLink(hashTag)} className={styles.header}>
          <Col className={styles.headerText}>
            <span className={styles.headerOval}> </span>
            <h1 className={styles.title}>
              <FormattedMessage id={title} />
            </h1>
          </Col>
          <Col className={styles.titleCol}>
            <div className={`${styles.titleImage} ${titleImgOffset}`}>
              <img src={titleImage} alt="Section" />
            </div>
          </Col>
        </Row>
        <Row>
          {children}
        </Row>
      </Container>
    </>
  );
};

export default FeatureSection;
