import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { isDivTop } from 'helpers/utils/html';
import mSignal, { SIGNALS } from 'helpers/constants/signals';

import { removeHashSignFromLink } from 'helpers/utils/url';
import styles from './SectionWrapper.module.scss';

interface FeatureSectionProps {
  title: string;
  titleImage: string;
  hashTag: string;
  showBreakline?: boolean;
}

const FeatureSectionWrapper: React.FC<FeatureSectionProps> = ({
  children,
  title,
  titleImage,
  hashTag,
  showBreakline = false,
}) => {
  const trackScrolling = (): void => {
    const wrappedElement = document.getElementById(removeHashSignFromLink(hashTag));
    if (isDivTop(wrappedElement)) {
      mSignal.dispatch(SIGNALS.ACTIVE_HUMAN_SCROLL, hashTag);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return (): void => {
      document.removeEventListener('scroll', trackScrolling);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breakLine = showBreakline ? (
    <hr className={styles.breakLine} />
  ) : null;
  return (
    <>
      <div className={styles.headerTag} id={removeHashSignFromLink(hashTag)}> </div>
      <Container className={styles.container}>
        {breakLine}
        <Row className={styles.header}>
          <Col className={styles.headerTitle}>
            <h2 className={styles.title}>
              <FormattedMessage id={title} />
            </h2>
          </Col>
          <Col className={styles.imageBg}>
            <div>
              <img src={titleImage} alt="Features title" />
            </div>
          </Col>
        </Row>
        {children}
      </Container>
    </>
  );
};

export default FeatureSectionWrapper;
