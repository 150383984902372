import React from 'react';
import { SectionConfigType } from 'types/features';
import { FeatureBlockInfo } from 'enums/features';

import addGroupIcon from 'images/features/webplatform/group-add.svg';
import mobileFriendlyIcon from 'images/features/webplatform/mobile-friendly.svg';
import settingsIcon from 'images/features/webplatform/settings.svg';
import relocationIcon from 'images/features/webplatform/relocation.svg';
import portNumberIcon from 'images/features/webplatform/add-to-home-screen.svg';
import formatListIcon from 'images/features/webplatform/format-list.svg';
import starIcon from 'images/features/webplatform/star.svg';
import publicIcon from 'images/features/webplatform/public.svg';
import ringVolume from 'images/features/webplatform/ring-volume.svg';
import syncIcon from 'images/features/webplatform/synch.svg';

import titleImage from 'images/features/sections/webplatform.png';

import AnchorLinks from '../links';
import Section from '../Core/Section';
import Block from '../Core/Block';

const webplTranslationRoot = 'components.features.administation.webplatform';

const WebPlatformConfig: Array<SectionConfigType> = [
  {
    title: `${webplTranslationRoot}.feature1.title`,
    description: `${webplTranslationRoot}.feature1.description`,
    icon: addGroupIcon,
  },
  {
    title: `${webplTranslationRoot}.feature2.title`,
    description: `${webplTranslationRoot}.feature2.description`,
    icon: mobileFriendlyIcon,
  },
  {
    title: `${webplTranslationRoot}.feature3.title`,
    description: `${webplTranslationRoot}.feature3.description`,
    icon: settingsIcon,
  },
  {
    title: `${webplTranslationRoot}.feature4.title`,
    description: `${webplTranslationRoot}.feature4.description`,
    icon: relocationIcon,
  },
  {
    title: `${webplTranslationRoot}.feature5.title`,
    description: `${webplTranslationRoot}.feature5.description`,
    icon: ringVolume,
  },
  {
    blockId: FeatureBlockInfo.PortNumber,
    translationRoot: webplTranslationRoot,
    title: `${webplTranslationRoot}.feature6.title`,
    description: `${webplTranslationRoot}.feature6.description`,
    icon: portNumberIcon,
  },
  {
    title: `${webplTranslationRoot}.feature7.title`,
    description: `${webplTranslationRoot}.feature7.description`,
    icon: publicIcon,
  },
  {
    title: `${webplTranslationRoot}.feature8.title`,
    description: `${webplTranslationRoot}.feature8.description`,
    icon: syncIcon,
  },
  {
    title: `${webplTranslationRoot}.feature9.title`,
    description: `${webplTranslationRoot}.feature9.description`,
    icon: formatListIcon,
    inactive: true,
  },
  {
    title: `${webplTranslationRoot}.feature10.title`,
    description: `${webplTranslationRoot}.feature10.description`,
    icon: starIcon,
    inactive: true,
  },
];

const WebPlatformSection: React.FC = () => {
  const webPlatformBlockArray = WebPlatformConfig.map((item) => (
    <Block
      key={item.title}
      blockId={item.blockId || ''}
      translationRoot={item.translationRoot || ''}
      title={item.title}
      description={item.description}
      inactive={item.inactive || false}
    >
      <img src={item.icon} alt="featureImage" />
    </Block>
  ));
  return (
    <Section
      title="components.features.administation.webplatform.title"
      titleImage={titleImage}
      titleImageUpOffset
      hashTag={AnchorLinks.webPlatform}
      showBreakLine={false}
    >
      {webPlatformBlockArray}
    </Section>
  );
};

export default WebPlatformSection;
