import React from 'react';
import titleImageIcon from 'images/features/userfeatures_header.svg';
import AnchorLinks from '../links';
import SectionWrapper from '../Core/SectionWrapper';
import AppSection from './app';

const UserFeatures: React.FC = () => (
  <SectionWrapper
    title="components.features.userfeatures.title"
    titleImage={titleImageIcon}
    hashTag={AnchorLinks.userFeatures}
  >
    <AppSection />
  </SectionWrapper>
);

export default UserFeatures;
