/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { useOutsideClickHandler } from 'helpers/hooks/interaction';

import styles from './Info.module.scss';

interface FeatureInfoProps {
  onClose: () => void;
}

const FeatureInfo: React.FC<FeatureInfoProps> = ({
  children, onClose,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClickHandler(() => { onClose(); }, wrapperRef);

  return (
    <div ref={wrapperRef} className={styles.container}>
      {children}
    </div>
  );
};

export default FeatureInfo;
