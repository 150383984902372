/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FeatureBlockInfo } from 'enums/features';
import FeatureInfo from '../Info';

import styles from './Block.module.scss';

interface FeatureBlockProps {
  blockId?: string;
  translationRoot?: string;
  title: string;
  description: string;
  inactive?: boolean;
  notice?: string;
}

const FeatureBlock: React.FC<FeatureBlockProps> = ({
  children,
  blockId = '',
  translationRoot = '',
  title,
  description,
  inactive = false,
  notice = '',
}) => {
  const [isInfo, setInfo] = useState(false);
  const [infoContent, setInfoContent] = useState((<span />));
  const titleCl = !inactive ? styles.title : styles.titleInactive;
  const inactiveTitlePostfix = inactive ? '*' : '';
  const inactiveNotice = inactive ? 'components.features.inactive' : '';
  const theNotice = notice !== '' ? notice : inactiveNotice;

  const isNotice = theNotice !== '';
  const noteSection = isNotice
    ? (
      <Row>
        <Col>
          <p className={styles.notice}>
            <FormattedMessage id={theNotice} />
          </p>
        </Col>
      </Row>
    ) : null;
  const descriptionBottom = isNotice ? styles.padWithNotice : '';

  const activateInfo = (): void => {
    let content = null;
    switch (blockId) {
      case FeatureBlockInfo.PortNumber:
      default:
        content = (
          <>
            <p><FormattedMessage id={`${translationRoot}.feature6.info1`} /></p>
            <p><FormattedMessage id={`${translationRoot}.feature6.info2`} /></p>
            <p><FormattedMessage id={`${translationRoot}.feature6.info3`} /></p>
          </>
        );
        break;
    }
    setInfoContent(content);
    setInfo(!isInfo);
  };

  const onCloseInfo = (): void => {
    setInfo(!isInfo);
  };

  const InfoTip = isInfo
    ? (
      <FeatureInfo onClose={onCloseInfo}>
        {infoContent}
      </FeatureInfo>
    ) : null;

  return (
    <Col className={styles.container} sm="6" md="4" lg="4" xl="4">
      <Row>
        <Col xs="2" sm="2" md="2" lg="12" xl="12" className={styles.colIcon}>
          {children}
        </Col>
        <Col xs="10" sm="10" md="10" lg="12" xl="12">
          <Row>
            <Col>
              <h2 className={titleCl}>
                <FormattedMessage id={title} />
                {inactiveTitlePostfix}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={`${styles.description} ${descriptionBottom}`}>
                <FormattedMessage
                  id={description}
                  values={{
                    a: (...chunks: string[]): JSX.Element => (
                      <a
                        role="button"
                        tabIndex={0}
                        onKeyPress={(): void => { /* required */ }}
                        onClick={activateInfo}
                        className={styles.infoLink}
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </p>
            </Col>
          </Row>
          {InfoTip}
          {noteSection}
        </Col>
      </Row>
    </Col>
  );
};

export default FeatureBlock;
